<template>
  <div>
    <submit-declarations-modal
      v-if="shipment.id && showDeclarationModal && canEdit"
      id="modal-submit-declarations"
      :resendables="resendables"
      :shipment="shipment"
      :selected-parcels="selectedParcels"
      :selected-containers="selectedContainers"
      :selected-all="selectedAll"
      :declaration-type="declarationType"
      @submit="submit"
    />

    <b-row>
      <b-col class="mt-2">
        <b-card-text v-if="resendables">
          Parcels that can be sent to customs again.
        </b-card-text>
        <b-card-text v-else>
          Parcels not sent to customs yet.
        </b-card-text>
      </b-col>
      <b-col class="text-right">
        <download-warehouse-excel-button
          v-if="!resendables"
          label="Download Excel for Warehouse"
          type="notSent"
          :disabled="containers.length === 0"
          :shipment-id="shipment.id"
          :declarationType="declarationType"
        />
        <b-button
          v-if="canEdit"
          v-b-modal.modal-submit-declarations
          variant="outline-success"
          class="m-1"
          :disabled="disabledH7Btn"
          @click="showDeclarationModal = true"
          @close="showDeclarationModal = false"
        >
          Send {{ declarationType.toUpperCase() }} declarations
        </b-button>
        <b-alert
          variant="danger"
          :show="issues.length > 0"
        >
          <div class="alert-body">
            Please repair manifest file before sending declaration
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <parcels-tree
          :shipment="shipment"
          :containers="containers"
          :data-loading="isLoading"
          data-loading-message="Loading..."
          :filter="resendables ? 'resendables' : 'notSent'"
          :event-hub="eventHub"
          :filters="filters"
          :declarationType="declarationType"
          :show-edit="canEdit"
          @sortDesc="fetchData(null, $event)"
          @selection="selection"
          @parcels="updateParcelsCollection"
        />

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BRow,
  BAlert,
} from 'bootstrap-vue'
import ParcelsTree from '@/views/shipments/declarations/ParcelsTree.vue'
import SubmitDeclarationsModal from '@/views/shipments/declarations/SubmitDeclarationsModal.vue'
import shipmentModel from '@/views/shipments/shipmentModel'
import DownloadWarehouseExcelButton from '@/views/shipments/declarations/components/classes/DownloadWarehouseExcelButton.vue'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleError from '@/views/components/errorHandler'

export default {
  components: {
    BButton,
    BCardText,
    BAlert,
    BCol,
    BRow,
    ParcelsTree,
    SubmitDeclarationsModal,
    DownloadWarehouseExcelButton,
  },
  props: {
    shipmentId: {
      type: String,
      default: null,
    },
    eventHub: {
      default: () => new Vue(),
    },
    filters: {
      type: Array,
      default: () => [],
    },
    issues: {
      type: Array,
      default: () => [],
    },
    resendables: {
      type: Boolean,
      default: false,
    },
    declarationType: {
      type: String,
      default: 'h7',
    },
  },
  data() {
    return {
      shipment: {},
      containers: [],
      selectedAll: false,
      showDeclarationModal: false,
      selectedContainers: [],
      selectedParcels: [],
      allParcelsData: [],
      isLoading: false,
      selectedContainNotArrivedParcels: false,
    }
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    disabledH7Btn() {
      return (this.selectedAll === false && this.selectedContainers.length === 0 && this.selectedParcels.length === 0)
        || this.issues.length > 0
    },
    companyId() {
      return this.$data.shipment.company.id
    },
  },
  created() {
    this.getShipment()
    this.fetchData()
    this.eventHub.$on('general-declaration-update', this.fetchData)
  },
  methods: {
    getShipment() {
      const cachedData = shipmentModel().getCachedShipment(this.shipmentId)
      if (cachedData === null) {
        shipmentModel().getShipment(this.shipmentId, this)
          .then(response => {
            shipmentModel().cacheShipment(response.data)
            this.shipment = response.data
          })
      } else {
        this.shipment = cachedData
      }
    },
    fetchData(activeTab, sortDesc = null) {
      if (!activeTab || activeTab === 'not-sent') {
        this.isLoading = true
        let url = `/v1/companies/${this.companyId}/shipments/${this.shipmentId}/declarations/${this.declarationType}/not-sent`
        if (this.resendables) {
          url = `/v1/companies/${this.companyId}/shipments/${this.shipmentId}/declarations/resendables`
        }
        this.$http.get(url, {
          params: {
            sortBy: 'container',
            sortDesc: sortDesc,
          },
        })
          .then(response => {
            this.isLoading = false
            this.containers = response.data.data
          })
      }
    },
    selection(data) {
      this.selectedAll = data.selectedAll
      this.selectedContainers = data.selectedContainers
      this.selectedParcels = data.selectedParcels
      this.checkForNotArrivedParcels()
    },
    submit(data) {
      let url = `/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/declarations`
      if (this.resendables) {
        url += '?resendables=1'
      }
      this.$http.post(url, {
        all: this.selectedAll,
        containers: this.selectedContainers,
        parcels: this.selectedParcels,
        previous_document_type: data.previous_document_type,
        previous_document_category: data.previous_document_category,
        previous_document_reference: data.previous_document_reference,
        send_arrived_to_facility: data.send_arrived_to_facility,
        warehouse_id: data.warehouse_id,
        has_arrived: data.has_arrived,
        customs_country: data.customs_country,
        declaration_type: this.declarationType,
        inland_mode_of_transport: data.inland_mode_of_transport,
        mode_of_transport_at_border: data.mode_of_transport_at_border,
      })
        .then(() => {
          this.eventHub.$emit('refresh-statistics')
          this.selectedAll = false
          this.selectedContainers = []
          this.selectedParcels = []
          this.allParcelsData = []
          this.eventHub.$emit('clear-selections')
          this.fetchData()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Declarations sent to customs',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    updateParcelsCollection(data) {
      this.allParcelsData = data.fullParcelsData
    },
    checkForNotArrivedParcels() {
      let notArrivedCount = 0
      let parcelHasArrivedEvent = false
      let containerData = null
      let parcelData = null

      this.selectedContainers.map(containerCode => {
        containerData = this.containers.find(container => container.code === containerCode)
        if (containerData.contain_not_arrived_parcels) {
          notArrivedCount += 1
        }
        return notArrivedCount
      })

      this.selectedParcels.map(parcelId => {
        parcelData = this.findParcelInContainer(parcelId)

        if (parcelData) {
          if (!parcelData.events.length) {
            notArrivedCount += 1
          } else {
            parcelHasArrivedEvent = false
            parcelData.events.map(parcelEvent => {
              if (parcelEvent.type === 'arrived_to_facility') {
                parcelHasArrivedEvent = true
              }
              return notArrivedCount
            })
            if (!parcelHasArrivedEvent) {
              notArrivedCount += 1
            }
          }
        }
        return notArrivedCount
      })

      this.selectedContainNotArrivedParcels = notArrivedCount > 0
    },
    findParcelInContainer(parcelId) {
      const foundParcel = this.allParcelsData
        .flatMap(containers => Object.values(containers))
        .flat()
        .find(parcel => parcel.id === parcelId)
      return foundParcel || null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
