<template>
  <div>

    <b-row>
      <b-col class="mt-2">
        <b-card-text>
          Reduced VAT Rate
        </b-card-text>
      </b-col>
    </b-row>
    <b-row>
      <b-col>

        <b-table
          :items="parcels"
          responsive
          :fields="fields"
          stacked="sm"
          show-empty
          empty-text="No matching records found"
          class="mb-0"
        >

          <template #cell(id)="data">
            <div v-if="data.item.specialCommodities">
              <b-button
                type="button"
                :variant="calculateSuccessVariant(data.item.specialCommodities.status)"
                :disabled="data.item.hasDeclarations"
                class="mr-1"
                @click="confirmVat(data.item.id)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                type="button"
                :variant="calculateDangerVariant(data.item.specialCommodities.status)"
                :disabled="data.item.hasDeclarations"
                @click="cancelVat(data.item.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </template>

          <template #cell(tracking_number)="data">
            {{ data.item.tracking_number }}
          </template>

          <template #cell(hs_code)="data">
            <div>
              {{ data.item.hs_code }}
            </div>
          </template>

          <template #cell(descriptions)="data">
            <div>
              {{ data.item.description }}
            </div>
          </template>

        </b-table>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCardText,
  BCol,
  BRow,
  BTable,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BCol,
    BRow,
    BTable,
    BButton,
  },
  data() {
    return {
      parcels: [],
      fields: [
        { key: 'id', label: 'Confirm reduced VAT' },
        { key: 'tracking_number', label: 'Tracking code', sortable: true },
        { key: 'hs_code', label: 'HS Code', sortable: true },
        { key: 'description', label: 'Description' },
      ],
    }
  },
  created() {
    this.fetchData()
  },
  props: ['shipmentId', 'eventHub'],
  methods: {
    calculateSuccessVariant(status) {
      let state = 'outline-primary'
      if (status === 'confirmed') {
        state = 'success'
      } else if (status === 'declined') {
        state = 'outline-success'
      }

      return state
    },
    calculateDangerVariant(status) {
      let state = 'outline-primary'
      if (status === 'confirmed') {
        state = 'outline-danger'
      } else if (status === 'declined') {
        state = 'danger'
      }

      return state
    },
    setState(id, state) {
      const result = []
      this.parcels.forEach(item => {
        if (item.id === id) {
          item.specialCommodities.status = state
        }
        result.push(item)
      })
      this.parcels = result
      this.eventHub.$emit('refresh-statistics')
    },
    confirmVat(id) {
      this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/reduced-vat/confirm/${id}`)
        .then(() => this.setState(id, 'confirmed'))
    },
    cancelVat(id) {
      this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/reduced-vat/cancel/${id}`)
        .then(() => this.setState(id, 'declined'))
    },
    fetchData() {
      this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/reduced-vat`)
        .then(response => {
          this.parcels = response.data.data
        })
    },
  },
}
</script>

<style lang="scss">

</style>
