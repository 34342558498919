<template>
  <div>
    <edit-parcel-items
      v-if="selectedDeclaration"
      id="edit-parcel-items"
      :parcel="selectedParcel"
      :event-hub="eventHub"
      :declaration="selectedDeclaration"
      :shipment="shipment"
      :readonly="!canEdit"
    />
    <invalidation-modal
      v-if="showInvalidation"
      style="top: 100px"
      :containers="selectedContainers"
      :parcels="selectedParcels"
      :shipment-id="shipment.id"
      :company-id="shipment.company_id"
      :event="eventHub"
      @invalidated="declarationInvalidated()"
    />
    <b-row>
      <b-col
        class="mt-2"
        cols="4"
      >
        <b-card-text>
          All declaration procedures completed.
        </b-card-text>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mt-1"
        cols="4"
      >
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="showType"
            :options="viewOptions"
            button-variant="outline-primary"
            :aria-describedby="ariaDescribedby"
            name="radios-btn-default"
            buttons
            @change="resetSelects"
          />
          <b-button
            v-if="showType === 'containers' && showInvalidation"
            variant="outline-danger"
            class="m-1"
            v-b-modal.invalidate-declaration-modal
          >
            Invalidate selected
          </b-button>
        </b-form-group>
      </b-col>
      <b-col
        class="text-right"
        cols="8"
      >
        <b-button
          variant="outline-success"
          class="m-1"
          :href="excelDownloadUrl"
        >
          Download as Excel
        </b-button>
        <download-warehouse-excel-button
          type="cleared"
          label="Download Excel for Warehouse"
          :disabled="declarations.length === 0"
          :shipment-id="shipment.id"
        />
        <b-button
          v-if="shipment.company.is_customs_invoice_allowed"
          v-b-tooltip.hover.top="'Download pdf for all customs invoices'"
          variant="outline-success"
          class="m-1"
          :href="invoicesDownloadUrl"
        >
          Download all customs invoices
        </b-button>
        <b-button
          v-if="canDownloadClearanceMessages()"
          variant="outline-success"
          class="m-1"
          :href="messagesDownloadUrl"
        >
          Download clearance messages
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="showType === 'parcels'">
      <b-col>
        <div class="overflow-x-scroll">
          <b-table
            :items="declarations"
            responsive
            :fields="fields"
            :busy="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            no-local-sorting
            show-empty
            empty-text="No matching records found"
            class="mb-0"
            :tbody-tr-class="rowClass"
          >
            <template #head()="data">
              <span class="text-nowrap">
                {{ data.label }}
              </span>
            </template>

            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center">
                <b-button-group>
                  <view-parcel-button
                    :declaration="data.item"
                    :parcel="data.item.parcel"
                    :event-hub="eventHub"
                  />
                  <download-pdf-button
                    :declaration="data.item"
                    :parcel="data.item.parcel"
                    :shipment="shipment"
                  />
                </b-button-group>
                <b-icon-archive
                  class="ml-1 cursor-pointer text-secondary"
                  v-if="data.item.parcel.is_archived"
                  v-b-tooltip.hover.top="'Buyer personal data is removed'"
                />
              </div>
            </template>

            <template #cell(tracking_number)="data">
              {{ data.item.parcel.tracking_number }}
            </template>

            <template #cell(container_code)="data">
              {{ data.item.parcel.container_code }}
            </template>

            <template #cell(updated_at)="data">
              {{ data.item.updated_at | formatDate }}
            </template>

          </b-table>
        </div>
        <pagination
          :event-hub="eventHub"
          :per-page="perPage"
        />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <parcels-tree
          :event-hub="eventHub"
          :shipment="shipment"
          :containers="containers"
          filter="cleared"
          clearance="true"
          :data-loading="loading"
          @sortDesc="fetchData()"
          @selection="selection"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BRow,
  BTable,
  BSpinner,
  VBTooltip,
  BButtonGroup,
  BIconArchive,
  BFormGroup,
  BFormRadioGroup,
} from 'bootstrap-vue'
import ParcelsTree from '@/views/shipments/declarations/ParcelsTree.vue'
import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'
import DownloadPdfButton from '@/views/shipments/declarations/components/DownloadPdfButton.vue'
import DownloadWarehouseExcelButton from '@/views/shipments/declarations/components/classes/DownloadWarehouseExcelButton.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import InvalidationModal from '@/views/shipments/declarations/modals/components/InvalidationModal.vue'

export default {
  components: {
    InvalidationModal,
    BIconArchive,
    BButtonGroup,
    BFormGroup,
    BFormRadioGroup,
    EditParcelItems,
    DownloadWarehouseExcelButton,
    BButton,
    BCardText,
    BCol,
    BRow,
    BTable,
    BSpinner,
    DownloadPdfButton,
    ViewParcelButton,
    Pagination,
    ParcelsTree,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['eventHub', 'shipment', 'filters', 'declarationType'],
  data() {
    return {
      loading: false,
      page: 1,
      perPage: 1000,
      selectedDeclaration: null,
      selectedParcel: null,
      showType: 'parcels',
      viewOptions: [
        { text: 'Parcels', value: 'parcels' },
        { text: 'Containers', value: 'containers' },
      ],
      containers: [],
      selectedAll: false,
      selectedContainers: [],
      selectedParcels: [],
      allParcelsData: [],
      excelDownloadUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/declarations/${this.declarationType}/cleared/excel`,
      messagesDownloadUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/messages/${this.declarationType}/cleared`,
      invoicesDownloadUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/invoices-download`,
      sortBy: null,
      isSortDirDesc: null,
      fields: [
        { key: 'actions', label: 'Actions' },
        { key: 'tracking_number', label: 'Tracking code', sortable: true },
        { key: 'container_code', label: 'Container', sortable: true },
        { key: 'lrn', label: 'LRN' },
        { key: 'mrn', label: 'MRN' },
        { key: 'updated_at', label: 'Declaration time' },
      ],
      declarations: [],
    }
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    url() {
      let url = `/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/declarations/${this.declarationType}/cleared`
      url += `?page=${this.page}`
      url += `&per_page=${this.perPage}`
      url += `&listView=${this.showType}`
      return url
    },
    showInvalidation() {
      return this.$permissions().hasPermission('edit shipment')
        && (this.selectedAll || this.selectedContainers.length || this.selectedParcels.length)
        && ['FI', 'RO'].includes(this.shipment.entry_country)
    },
  },
  watch: {
    filters() {
      this.fetchData()
    },
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
    showType() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
    this.eventHub.$on('general-declaration-update', this.fetchData)
    this.eventHub.$on('set-view-parcel', this.viewParcel)
  },
  mounted() {
    this.eventHub.$on('pageChange', newPage => {
      this.page = newPage
      this.fetchData()
    })
  },
  methods: {
    selection(data) {
      this.selectedAll = data.selectedAll
      this.selectedContainers = data.selectedContainers
      this.selectedParcels = data.selectedParcels
    },
    rowClass(item) {
      return item?.parcel?.is_archived ? 'archived-row' : ''
    },
    viewParcel(declaration, parcel) {
      this.selectedDeclaration = declaration
      this.selectedParcel = parcel
    },
    fetchData(activeTab) {
      if (!activeTab || activeTab === 'cleared') {
        this.loading = true
        this.$http.get(this.searchQuery(this.url), {
          params: {
            sortBy: this.sortBy,
            sortDesc: this.isSortDirDesc,
          },
        })
          .then(response => {
            this.loading = false
            if (this.showType === 'parcels') {
              this.declarations = response.data.data
              this.$props.eventHub.$emit('updateMeta', response.data.meta)
            } else {
              this.containers = response.data.data
            }
          })
      }
    },
    searchQuery(url) {
      // Apply filters
      if (Object.keys(this.filters).length > 0) {
        const q = []
        Object.keys(this.filters).forEach(key => {
          if (this.filters[key] !== null) {
            q.push(`${key}=${this.filters[key]}`)
          }
        })
        url += `?${q.join('&')}`
      }

      return url
    },
    canDownloadClearanceMessages() {
      return this.shipment.company.config?.canDownloadClearanceMessages
    },
    declarationInvalidated() {
      this.$bvModal.hide('invalidate-declaration-modal')
    },
    resetSelects() {
      this.selectedAll = false
      this.selectedParcels = []
      this.selectedContainers = []
    },
  },
}
</script>

<style lang="scss">
.archived-row {
  background-color: #fafafa;
  color: #999;
  opacity: 0.8;
}
</style>
