<template>
  <div>
    <edit-parcel-items
      v-if="selectedDeclaration || selectedParcel"
      id="edit-parcel-items"
      :parcel="selectedParcel"
      :event-hub="eventHub"
      :declaration="selectedDeclaration"
      :shipment="selectedParcel ? selectedParcel.shipment : null"
      :readonly="!canEdit"
    />
    <b-row>
      <b-col
        class="mt-2"
        cols="9"
      >
        <b-card-text>
          List of cancelled parcel declarations
        </b-card-text>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="overflow-x-scroll">
          <b-table
            :items="declarations"
            responsive
            :fields="fields"
            :busy="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            no-local-sorting
            show-empty
            empty-text="No matching records found"
            class="mb-0"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>

            <template #head()="data">
              <span class="text-nowrap">
                {{ data.label }}
              </span>
            </template>

            <template #cell(actions)="data">
              <view-parcel-button
                :declaration="data.item"
                :parcel="data.item.parcel"
                :event-hub="eventHub"
              />
            </template>

            <template #cell(tracking_number)="data">
              {{ data.item.parcel.tracking_number }}
            </template>

            <template #cell(container_code)="data">
              {{ data.item.parcel.container_code }}
            </template>

            <template #cell(updated_at)="data">
              {{ data.item.updated_at | formatDate }}
            </template>

          </b-table>
        </div>
        <pagination
          :event-hub="eventHub"
          :per-page="perPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCardText,
  BCol,
  BRow,
  BTable,
  BSpinner,
} from 'bootstrap-vue'
import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'

export default {
  components: {
    EditParcelItems,
    ViewParcelButton,
    BCardText,
    BCol,
    BRow,
    BTable,
    BSpinner,
    Pagination,
  },
  props: ['eventHub', 'shipment'],
  data() {
    return {
      loading: false,
      page: 1,
      perPage: 1000,
      selectedDeclaration: null,
      selectedParcel: null,
      sortBy: null,
      isSortDirDesc: null,
      fields: [
        { key: 'actions', label: 'Actions' },
        { key: 'tracking_number', label: 'Tracking code', sortable: true },
        { key: 'container_code', label: 'Container', sortable: true },
        { key: 'lrn', label: 'LRN' },
        { key: 'mrn', label: 'MRN' },
        { key: 'updated_at', label: 'Declaration time' },
      ],
      declarations: [],
    }
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    url() {
      let url = `/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/cancelled`
      url += `?page=${this.page}`
      url += `&per_page=${this.perPage}`
      return url
    },
  },
  watch: {
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
  },
  created() {
    this.eventHub.$on('update-parcel-items', () => {
      this.selectedDeclaration = null
      this.selectedParcel = null
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    })
    this.eventHub.$on('general-declaration-update', this.fetchData)
    this.fetchData()
    this.eventHub.$on('set-view-parcel', this.viewParcel)
    this.eventHub.$on('pageChange', newPage => {
      this.page = newPage
      this.fetchData()
    })
  },
  methods: {
    viewParcel(declaration, parcel) {
      this.selectedDeclaration = declaration
      this.selectedParcel = parcel
    },
    fetchData(activeTab) {
      if (!activeTab || activeTab === 'cancelled') {
        this.loading = true
        this.$http.get(this.url, {
          params: {
            sortBy: this.sortBy,
            sortDesc: this.isSortDirDesc,
          },
        })
          .then(response => {
            this.loading = false
            this.declarations = response.data.data
            this.$props.eventHub.$emit('updateMeta', response.data.meta)
          })
      }
    },
  },
}
</script>
