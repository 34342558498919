<template>
  <div>
    <edit-parcel-items
      v-if="selectedEditParcel"
      id="edit-parcel-items"
      :parcel="selectedEditParcel"
      :event-hub="eventHub"
      :shipment="shipment"
      :readonly="!canEdit"
    />
    <b-row>
      <b-col class="mt-2">
        <b-card-text>
          Parcels need additional information. Please add comment and attachment(s).
        </b-card-text>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          :items="parcels"
          responsive
          :fields="fields"
          :busy="loading"
          show-empty
          empty-text="No matching records found"
          class="mb-0"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(actions)="data">
            <view-parcel-button
              v-if="!canEdit"
              :declaration="data.item.low_value_declaration"
              :parcel="data.item"
              :event-hub="eventHub"
            />
            <b-button
              v-if="canEdit && (!data.item.mrn || data.item.mrn.length === 0)"
              v-b-modal.edit-parcel-items
              type="button"
              variant="warning"
              @click="editDeclaration(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </template>

          <template #cell(tracking_number)="data">
            {{ data.item.tracking_number }}
          </template>

          <template #cell(container_code)="data">
            {{ data.item.container_code }}
          </template>

          <template #cell(descriptions)="data">
            <div>
              {{ data.item.items.map(function (parcelItem) {return parcelItem.description}).join(', ') }}
            </div>
          </template>

          <template #cell(hs_codes)="data">
            <div>
              {{ data.item.items.map(function (parcelItem) {return parcelItem.hs_code}).join(', ') }}
            </div>
          </template>

          <template #cell(value)="data">
            <div
              v-if="data.item.items.length > 0"
              class="text-right"
            >
              {{ data.item.value_eur || data.item.items.reduce(function (sum, parcelItem) {return sum + parseFloat(parcelItem.value)}, 0) | formatNumber(2) }}
              {{ data.item.value_eur ? 'EUR' : data.item.transport_cost_currency }}
            </div>
          </template>

        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCardText,
  BCol,
  BRow,
  BTable,
  BButton, BSpinner,
} from 'bootstrap-vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'

export default {
  components: {
    ViewParcelButton,
    BSpinner,
    BCardText,
    BCol,
    BRow,
    BTable,
    BButton,
    EditParcelItems,
  },
  props: ['shipmentId', 'eventHub', 'shipment', 'filters'],
  data() {
    return {
      loading: false,
      selectedEditParcel: null,
      fields: [
        { key: 'actions', label: 'Actions' },
        { key: 'tracking_number', label: 'Tracking code' },
        { key: 'container_code', label: 'Container' },
        { key: 'descriptions', label: 'Description' },
        { key: 'hs_codes', label: 'HS code' },
        { key: 'value', label: 'Value' },
      ],
      parcels: [],
    }
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    computedParcel() {
      if (this.selectedEditParcel !== null) {
        return this.selectedEditParcel.id
      }
      return null
    },
    computedParcelData() {
      return this.selectedEditParcel
    },
  },
  created() {
    this.eventHub.$on('update-parcel-items', this.updateParcelItems)
    this.eventHub.$on('general-declaration-update', this.fetchData)
    this.eventHub.$on('set-view-parcel', this.viewParcel)
    this.fetchData()
  },
  methods: {
    updateParcelItems() {
      this.selectedEditParcel = null
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    },
    viewParcel(declaration, parcel) {
      this.selectedEditParcel = parcel
    },
    editDeclaration(data) {
      this.selectedEditParcel = data
    },
    fetchData(activeTab) {
      if (!activeTab || activeTab === 'price-over') {
        this.loading = true
        this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/price-over-h7-maximum`)
          .then(response => {
            this.parcels = response.data.data
            this.loading = false
          })
      }
    },
  },
}
</script>

<style scoped>

</style>
