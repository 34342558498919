<template>
  <b-row v-if="shipment">
    <b-col>
      <app-collapse>
        <app-collapse-item
          title="Filters"
          class="mb-1"
        >
          <b-form>
            <b-row>
              <b-col
                col
                lg="1"
              >
                <b-form-group
                  label="Has note or file"
                  label-for="has-note"
                >
                  <b-form-checkbox
                    id="has-note"
                    v-model="filters.has_note"
                    switch
                    class="mt-50"
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <b-button
                  class="mt-2"
                  type="button"
                  variant="primary"
                  @click="filterData()"
                >
                  Filter
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </app-collapse-item>
      </app-collapse>
      <b-card>
        <b-card-actions
          ref="loadingCard"
          no-actions
        >
          <b-tabs>
            <!-- Tab prohibited -->
            <b-tab
              v-if="stats.prohibited || isActive('prohibited')"
              href="#prohibited"
              :active="isActive('prohibited')"
            >
              <template #title>
                <strong>Prohibited articles <b-badge>{{ stats.prohibited }}</b-badge></strong>
              </template>
              <prohibited
                :event-hub="eventHub"
                :shipment="shipment"
              />
            </b-tab>
            <!-- Tab reduced vat -->
            <b-tab
              v-if="stats.reducedVat || isActive('reduced-vat')"
              lazy
              href="#reduced-vat"
              :active="isActive('reduced-vat')"
            >
              <template #title>
                <strong class="text-primary">Reduced VAT Rate <b-badge
                  variant="primary"
                >{{ stats.reducedVat }}</b-badge></strong>
              </template>
              <reduced-vat
                :shipment-id="this.$attrs.shipmentId"
                :event-hub="eventHub"
              />
            </b-tab>
            <!-- Tab not sent -->
            <b-tab
              lazy
              href="#not-sent"
              :active="isActive('not-sent') || isActive('not-sent-h1')"
            >
              <template #title>
                <strong>Not sent to customs
                  <b-badge v-if="stats.notSent">{{ stats.notSent }}</b-badge>
                </strong>
              </template>
              <not-sent
                :shipment-id="$attrs.shipmentId"
                :event-hub="eventHub"
                :filters="get_filters"
                :issues="issues"
                :declaration-type="declarationType"
              />
            </b-tab>
            <!-- Tab resendables -->
            <b-tab
              v-if="stats.resendables || isActive('resendables')"
              lazy
              href="#resendables"
              :active="isActive('resendables')"
            >
              <template #title>
                <strong>Resendables <b-badge v-if="stats.resendables">{{ stats.resendables }}</b-badge></strong>
              </template>
              <not-sent
                :shipment-id="$attrs.shipmentId"
                :event-hub="eventHub"
                :filters="get_filters"
                :issues="issues"
                resendables
              />
            </b-tab>
            <!-- Tab preparing to send -->
            <b-tab
              v-if="stats.preparingToSend || isActive('preparing-to-send') || isActive('preparing-to-send-h1')"
              lazy
              :active="isActive('preparing-to-send') || isActive('preparing-to-send-h1')"
              href="#preparing-to-send"
            >
              <template #title>
                <strong>Preparing to send <b-badge>{{ stats.preparingToSend }}</b-badge></strong>
              </template>
              <waiting-for-customs
                :shipment="shipment"
                :event-hub="eventHub"
                :filters="get_filters"
                preparing-to-send
                :declaration-type="declarationType"
              />
            </b-tab>
            <!-- Tab waiting for customs -->
            <b-tab
              v-if="stats.waitingForCustoms || isActive('waiting-customs') || isActive('waiting-customs-h1')"
              lazy
              :active="isActive('waiting-customs') || isActive('waiting-customs-h1')"
              href="#waiting-customs"
            >
              <template #title>
                <strong>Waiting for customs
                  <b-badge>{{ stats.waitingForCustoms }}</b-badge>
                </strong>
              </template>
              <waiting-for-customs
                :shipment="shipment"
                :event-hub="eventHub"
                :filters="get_filters"
                :declaration-type="declarationType"
              />
            </b-tab>
            <!-- Tab needs attention -->
            <b-tab
              v-if="stats.needsAttention || isActive('needs-attention') || isActive('needs-attention-h1')"
              lazy
              :active="isActive('needs-attention') || isActive('needs-attention-h1')"
              href="#needs-attention"
            >
              <template #title>
                <strong class="text-warning">Needs attention <b-badge variant="warning">{{ stats.needsAttention }}</b-badge>
                </strong>
              </template>
              <needs-attention
                :shipment-id="this.$attrs.shipmentId"
                :event-hub="eventHub"
                :shipment="shipment"
                :filters="get_filters"
                :declaration-type="declarationType"
              />
            </b-tab>
            <!-- Draft submitted -->
            <b-tab
              v-if="stats.draftCreated || isActive('draft-created-h1')"
              lazy
              :active="isActive('draft-created-h1')"
              href="#draft-created"
            >
              <template #title>
                <strong class="text-warning">Draft submitted <b-badge variant="warning">{{ stats.draftCreated }}</b-badge>
                </strong>
              </template>
              <draft-created
                :shipment-id="this.$attrs.shipmentId"
                :event-hub="eventHub"
                :shipment="shipment"
                :filters="get_filters"
                :declaration-type="'h1'"
              />
            </b-tab>
            <!-- Tab buyer country not allowed -->
            <b-tab
              v-if="stats.buyerCountryNotAllowed || isActive('buyer-country-not-allowed')"
              lazy
              :active="isActive('buyer-country-not-allowed')"
              href="#buyer-country-not-allowed"
            >
              <template #title>
                <strong class="text-danger">Buyer country not allowed <b-badge variant="danger">{{ stats.buyerCountryNotAllowed }}</b-badge></strong>
              </template>
              <buyer-country-not-allowed
                :shipment-id="this.$attrs.shipmentId"
                :event-hub="eventHub"
                :shipment="shipment"
                :filters="get_filters"
              />
            </b-tab>
            <!-- Tab price over -->
            <b-tab
              v-if="stats.priceOver || isActive('price-over')"
              lazy
              :active="isActive('price-over')"
              href="#price-over"
            >
              <template #title>
                <strong class="text-warning">Price over <b-badge variant="warning">{{ stats.priceOver }}</b-badge></strong>
              </template>
              <price-over
                :shipment-id="this.$attrs.shipmentId"
                :event-hub="eventHub"
                :shipment="shipment"
                :filters="get_filters"
              />
            </b-tab>
            <!-- Tab held by customs -->
            <b-tab
              v-if="stats.heldByCustoms || isActive('held')"
              lazy
              :active="isActive('held')"
              href="#held"
            >
              <template #title>
                <strong class="text-warning">Held by customs <b-badge
                  variant="warning"
                >{{ stats.heldByCustoms }}</b-badge></strong>
              </template>
              <held-by-customs
                :shipment="shipment"
                :event-hub="eventHub"
                :filters="get_filters"
              />
            </b-tab>
            <!-- Tab needs documents -->
            <b-tab
              v-if="stats.needsDocuments || isActive('needs-documents')"
              lazy
              :active="isActive('needs-documents')"
              href="#needs-documents"
            >
              <template #title>
                <strong class="text-warning">Needs documents <b-badge
                  variant="warning"
                >{{ stats.needsDocuments }}</b-badge></strong>
              </template>
              <needs-documents
                :event-hub="eventHub"
                :shipment="shipment"
                :filters="get_filters"
              />
            </b-tab>
            <!-- Tab waiting for arrival -->
            <b-tab
              v-if="stats.waitingForArrival || isActive('waiting-arrival')"
              lazy
              :active="isActive('waiting-arrival')"
              href="#waiting-arrival"
            >
              <template #title>
                <strong>Waiting for arrival <b-badge>{{ stats.waitingForArrival }}</b-badge></strong>
              </template>
              <waiting-for-arrival
                :shipment="shipment"
                :event-hub="eventHub"
                :filters="get_filters"
                :declaration-type="declarationType"
              />
            </b-tab>
            <!-- Tab cleared -->
            <b-tab
              lazy
              href="#cleared"
              :active="isActive('cleared') || isActive('cleared-h1')"
            >
              <template #title>
                <strong class="text-success">Cleared
                  <b-badge
                    v-if="stats.cleared"
                    variant="success"
                  >
                    {{ stats.cleared }}
                  </b-badge>
                </strong>
              </template>
              <cleared
                :shipment="shipment"
                :event-hub="eventHub"
                :filters="get_filters"
                :declaration-type="declarationType"
              />
            </b-tab>
            <!-- Tab cancelled -->
            <b-tab
              v-if="stats.cancelled || isActive('cancelled')"
              lazy
              :active="isActive('cancelled')"
              href="#waiting-customs"
            >
              <template #title>
                <strong>Cancelled <b-badge>{{ stats.cancelled }}</b-badge></strong>
              </template>
              <cancelled
                :shipment="shipment"
                :event-hub="eventHub"
                :filters="get_filters"
              />
            </b-tab>
          </b-tabs>
        </b-card-actions>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard, BForm,
  BFormGroup,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import Cleared from '@/views/shipments/declarations/Cleared.vue'
import HeldByCustoms from '@/views/shipments/declarations/HeldByCustoms.vue'
import PriceOver from '@/views/shipments/declarations/PriceOver.vue'
import NeedsAttention from '@/views/shipments/declarations/NeedsAttention.vue'
import DraftCreated from '@/views/shipments/declarations/DraftCreated.vue'
import NotSent from '@/views/shipments/declarations/NotSent.vue'
import Prohibited from '@/views/shipments/declarations/Prohibited.vue'
import WaitingForArrival from '@/views/shipments/declarations/WaitingForArrival.vue'
import WaitingForCustoms from '@/views/shipments/declarations/WaitingForCustoms.vue'
import Cancelled from '@/views/shipments/declarations/Cancelled.vue'
import Vue from 'vue'
import ReducedVat from '@/views/shipments/declarations/ReducedVat.vue'
import shipmentModel from '@/views/shipments/shipmentModel'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import NeedsDocuments from '@/views/shipments/declarations/NeedsDocuments.vue'
import BuyerCountryNotAllowed from '@/views/shipments/declarations/BuyerCountryNotAllowed.vue'

export default {
  components: {
    BuyerCountryNotAllowed,
    NeedsDocuments,
    DraftCreated,
    AppCollapse,
    AppCollapseItem,
    BForm,
    ReducedVat,
    BBadge,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BFormGroup,
    BButton,
    BFormCheckbox,
    BCardActions,
    Cleared,
    HeldByCustoms,
    NeedsAttention,
    PriceOver,
    NotSent,
    Prohibited,
    WaitingForArrival,
    WaitingForCustoms,
    Cancelled,
  },
  data() {
    return {
      shipment: null,
      eventHub: new Vue(),
      statistics: {
        cleared: {
          h7: 0,
        },
        heldByCustoms: 0,
        needsAttention: 0,
        draftCreated: 0,
        notSent: 0,
        priceOver: 0,
        prohibited: 0,
        reducedVat: 0,
        waitingForArrival: 0,
        waitingForCustoms: {
          h7: 0,
        },
        preparingToSend: 0,
        cancelled: 0,
        resendables: 0,
      },
      filters: {
        has_note: false,
      },
      search_filters: {},
    }
  },
  computed: {
    declarationType() {
      return this.$attrs.tab.endsWith('-h1') ? 'h1' : 'h7'
    },
    get_filters() {
      return this.search_filters
    },
    issues() {
      let issues = []
      if (this.shipment.files) {
        this.shipment.files.forEach(val => {
          if (val.issues && val.issues.length > 0) {
            issues = issues.concat(val.issues)
          }
        })
      }

      return issues
    },
    stats() {
      const result = {}
      Object.keys(this.statistics).forEach(key => {
        result[key] = this.statistics[key]?.[this.declarationType]
      })
      return result
    },
  },
  mounted() {
    this.updateStatistics()
  },
  created() {
    this.eventHub.$on('refresh-statistics', this.updateStatistics)

    // Pusher handling
    const channel = this.$pusher.subscribe(`shipment-${this.$attrs.shipmentId}`)

    // Unbind existing events
    channel.unbind_all()

    // Manifest event
    channel.bind('declaration-status-sent', () => {
      this.updateStatistics()
    })

    // Update statistics event
    channel.bind('refresh-statistics', () => {
      this.updateStatistics()
    })

    // Declaration status changes
    channel.bind('declaration-status', () => {
      this.updateStatistics()
      this.eventHub.$emit('general-declaration-update', this.$attrs.tab)
    })
    this.getShipment()
  },
  methods: {
    getShipment() {
      const cachedData = shipmentModel().getCachedShipment(this.$attrs.shipmentId)
      if (cachedData === null) {
        shipmentModel().getShipment(this.$attrs.shipmentId, this)
          .then(response => {
            shipmentModel().cacheShipment(response.data)
            this.shipment = response.data
            this.updateTitle(response.data)
          })
      } else {
        this.shipment = cachedData
        this.updateTitle(cachedData)
      }
    },
    filterData() {
      this.search_filters = { ...this.filters }
    },
    isActive(tab) {
      return this.$attrs.tab === tab
    },
    updateTitle(data) {
      this.$route.meta.pageTitle = `Shipment (${data.document_number}) customs declarations`
      this.$globalEvent.$emit('update-page-title', this.$route.meta.pageTitle)

      if (this.$profile().data.user.companies.length > 1) {
        this.$route.meta.company = data.company
        this.$globalEvent.$emit('update-page-company', this.$route.meta.company)
      }
    },
    updateStatistics() {
      if (this.$refs.loadingCard) {
        this.$refs.loadingCard.showLoading = true
      }
      return this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.$attrs.shipmentId}/declarations/statistics`)
        .then(response => {
          if (this.$refs.loadingCard) {
            this.$refs.loadingCard.showLoading = false
          }
          const statistics = {}
          statistics.buyerCountryNotAllowed = { h7: response.data.data.buyer_country_not_allowed }
          statistics.cleared = { h7: response.data.data.cleared }
          statistics.heldByCustoms = { h7: response.data.data.held_by_customs }
          statistics.needsAttention = { h7: response.data.data.needs_attention }
          statistics.notSent = { h7: response.data.data.not_sent }
          statistics.priceOver = { h7: response.data.data.price_over }
          statistics.prohibited = { h7: response.data.data.prohibited }
          statistics.reducedVat = { h7: response.data.data.reduced_vat }
          statistics.waitingForArrival = { h7: response.data.data.waiting_for_arrival }
          statistics.waitingForCustoms = { h7: response.data.data.waiting_for_customs }
          statistics.preparingToSend = { h7: response.data.data.preparing_to_send }
          statistics.cancelled = { h7: response.data.data.cancelled }
          statistics.resendables = { h7: response.data.data.resendables }
          statistics.needsDocuments = { h7: response.data.data.needs_documents }
          if (this.shipment?.is_h1_allowed) {
            statistics.notSent.h1 = response.data.data.not_sent_h1
            statistics.preparingToSend.h1 = response.data.data.preparing_to_send_h1
            statistics.waitingForCustoms.h1 = response.data.data.waiting_for_customs_h1
            statistics.needsAttention.h1 = response.data.data.needs_attention_h1
            statistics.draftCreated = { h1: response.data.data.draft_created_h1 }
            statistics.cleared.h1 = response.data.data.cleared_h1
          }
          this.statistics = statistics
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
