<template>
  <div>
    <edit-parcel-items
      v-if="selectedEditParcel"
      id="edit-parcel-items"
      :parcel="selectedEditParcel"
      :event-hub="eventHub"
      :declaration="computedDeclarationData"
      :shipment="shipment"
      :readonly="!canEdit"
    />
    <status-information
      v-if="informationItem"
      :item="informationItem"
    />
    <resend-declarations
      v-if="shipment.id"
      id="resend-declarations"
      :customs="shipment.entry_country"
      :shipment="shipment"
      :event-hub="eventHub"
      :declaration-type="declarationType"
      status="draft_created"
    />
    <b-row>
      <b-col class="mt-2">
        <b-card-text>
          Declarations submitted to customs in draft mode. Add missing details and changes using customs portal.
        </b-card-text>
      </b-col>
      <b-col
        v-if="canEdit"
        class="text-right"
      >
        <b-button
          v-b-modal.resend-declarations
          variant="outline-success"
          class="m-1"
        >
          Resend all to customs
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="overflow-x-scroll">
          <b-table
            :items="parcels"
            responsive
            :fields="fields"
            :busy="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            no-local-sorting
            show-empty
            empty-text="No matching records found"
            class="mb-0"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>

            <template #head()="data">
              <span class="text-nowrap">
                {{ data.label }}
              </span>
            </template>

            <template #cell(actions)="data">
              <b-button
                v-if="canEdit"
                v-b-modal.edit-parcel-items
                type="button"
                variant="warning"
                @click="editDeclaration(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </template>

            <template #cell(tracking_number)="data">
              {{ data.item.parcel.tracking_number }}
            </template>

            <template #cell(container_code)="data">
              {{ data.item.parcel.container_code }}
            </template>

            <template #cell(descriptions)="data">
              <div>
                {{ data.item.parcel.items.map(function (parcelItem) {return parcelItem.description}).join(', ') }}
              </div>
            </template>

            <template #cell(hs_codes)="data">
              <div>
                <b-row
                  v-for="(parcelItem, index) in data.item.parcel.items"
                  :key="index"
                >
                  <span
                    v-if="getItemError(index, data.item.status_info, parcelItem.hs_code)"
                    style="color: #780000; font-weight: bold;"
                  >
                    {{ parcelItem.hs_code }}
                  </span>
                  <span v-if="!getItemError(index, data.item.status_info, parcelItem.hs_code)">{{ parcelItem.hs_code }}</span>
                </b-row>
              </div>
            </template>

            <template #cell(value)="data">
              <div
                v-if="data.item.parcel.items.length > 0"
                class="text-right"
              >
                {{ data.item.parcel.items.reduce(function (sum, parcelItem) {return sum + parseFloat(parcelItem.value)}, 0) | formatNumber(2) }}
              </div>
            </template>

            <template #cell(status_info)="data">
              <div v-if="data.item.status_info !== null && data.item.status_info['errors']">
                <span
                  v-b-modal.modal-status-information
                  class="text-primary text-nowrap"
                  @click="informationItem = data.item"
                >
                  Show errors
                  <b-badge
                    variant="light"
                    class="text-primary"
                  >
                    {{ data.item.status_info['errors'].length }}
                  </b-badge>
                </span>
              </div>
            </template>

          </b-table>
        </div>
        <pagination
          :event-hub="eventHub"
          :per-page="perPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCardText,
  BCol,
  BRow,
  BTable,
  BButton,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import StatusInformation from '@/views/shipments/declarations/modals/StatusInformation.vue'
import ResendDeclarations from '@/views/shipments/declarations/modals/ResendDeclarations.vue'

export default {
  components: {
    ResendDeclarations,
    BBadge,
    StatusInformation,
    Pagination,
    BCardText,
    BCol,
    BRow,
    BTable,
    BButton,
    BSpinner,
    EditParcelItems,
  },
  props: ['shipmentId', 'eventHub', 'shipment', 'filters', 'declarationType'],
  data() {
    const fields = [
      { key: 'actions', label: 'Actions' },
      { key: 'tracking_number', label: 'Tracking code', sortable: true },
      { key: 'container_code', label: 'Container', sortable: true },
      { key: 'descriptions', label: 'Description' },
      { key: 'hs_codes', label: 'HS code' },
      { key: 'value', label: 'Value' },
      { key: 'lrn', label: 'LRN' },
      { key: 'mrn', label: 'MRN' },
      { key: 'status_info', label: 'Errors' },
    ]
    return {
      loading: false,
      informationItem: null,
      page: 1,
      perPage: 1000,
      selectedEditParcel: null,
      selectedEditDeclaration: null,
      sortBy: null,
      isSortDirDesc: null,
      fields: fields,
      parcels: [],
    }
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    computedDeclarationData() {
      if (this.selectedEditDeclaration !== null) {
        return this.selectedEditDeclaration
      }
      return null
    },
    url() {
      let url = `/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/${this.declarationType}/draft-created`
      url += `?page=${this.page}`
      url += `&per_page=${this.perPage}`
      return url
    },
  },
  watch: {
    filters() {
      this.fetchData()
    },
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
  },
  created() {
    this.eventHub.$on('update-parcel-items', this.updateParcelItems)
    this.eventHub.$on('general-declaration-update', this.fetchData)
    this.fetchData()
  },
  mounted() {
    this.eventHub.$on('pageChange', newPage => {
      this.page = newPage
      this.fetchData()
    })
    this.eventHub.$on('renewDeclarationList', () => {
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    })
  },
  methods: {
    canSeeErrors() {
      return this.$permissions().hasRole('Feeport Admin')
    },
    getItemError(index, errorList, hsCode) {
      let result = false
      if (errorList !== null) {
        errorList.errors.forEach(error => {
          if (error.item_reference && parseInt(error.item_reference, 10) === index + 1) {
            result = error
          } else if (hsCode && error.declaration_value === hsCode && error.field === 'parcel_item.hs_code') {
            result = error
          }
        })
      }

      return result
    },
    updateParcelItems(parcelId) {
      this.$http.put(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations`, { parcels: [parcelId] })
      this.selectedEditParcel = null
      this.selectedEditDeclaration = null
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    },
    editDeclaration(data) {
      this.selectedEditParcel = data.parcel
      this.selectedEditDeclaration = data
    },
    fetchData(activeTab) {
      if (!activeTab || activeTab === 'draft-created-h1') {
        this.loading = true
        this.$http.get(this.searchQuery(this.url), {
          params: {
            sortBy: this.sortBy,
            sortDesc: this.isSortDirDesc,
          },
        })
          .then(response => {
            this.loading = false
            this.parcels = response.data.data
            this.$props.eventHub.$emit('updateMeta', response.data.meta)
          })
      }
    },
    searchQuery(url) {
      // Apply filters
      if (Object.keys(this.filters).length > 0) {
        const q = []
        Object.keys(this.filters).forEach(key => {
          if (this.filters[key] !== null) {
            q.push(`${key}=${this.filters[key]}`)
          }
        })
        url += `?${q.join('&')}`
      }

      return url
    },
  },
}
</script>
